<template>
  <div>
    <h2 class="text-center">合约</h2>

    <p class="situation-kpi-title m-b-5">签约类型</p>
    <Row  class="table-title text-right" :gutter="8">
      <i-col span="6" class="text-left">类型</i-col>
      <i-col span="6">签约额(万元)</i-col>
      <i-col span="6">占比</i-col>
      <i-col span="6">金额涨幅</i-col>
    </Row>
    <Row v-for="(item,index) in listContractRevenueByTypeData" :key="'1_' + index" :gutter="8" class="p-t-10 text-right">
      <i-col span="6" class="text-left">{{item.name}}</i-col>
      <i-col span="6">{{formatMoneyByThousand(item.value)}}</i-col>
      <i-col span="6">{{item.rate}}%</i-col>
      <i-col span="6">
        <span :class="getChainRateColor(item.chainRate)">{{item.chainRate}}%</span>
      </i-col>
    </Row>
    <p v-if="listContractRevenueByTypeData.length===0" class="remark p-t-20 text-center">暂无数据</p>

    <p class="situation-kpi-title m-t-20 m-b-5">客户贡献</p>
    <Row  class="table-title text-right" :gutter="8">
      <i-col span="6" class="text-left">类型</i-col>
      <i-col span="6">签约额(万元)</i-col>
      <i-col span="6">占比</i-col>
      <i-col span="6">金额涨幅</i-col>
    </Row>
    <Row v-for="(item,index) in listContractRevenueByClientData" :key="'2_' + index" :gutter="8" class="p-t-10 text-right">
      <i-col span="6" class="text-left">{{item.name}}</i-col>
      <i-col span="6">{{formatMoneyByThousand(item.value)}}</i-col>
      <i-col span="6">{{item.rate}}%</i-col>
      <i-col span="6">
        <span :class="getChainRateColor(item.chainRate)">{{item.chainRate}}%</span>
      </i-col>
    </Row>
    <p v-if="listContractRevenueByClientData.length===0" class="remark p-t-20 text-center">暂无数据</p>

    <p class="situation-kpi-title m-t-20 m-b-5">代理商签约额</p>
    <Row  class="table-title text-right" :gutter="8">
      <i-col span="10" class="text-left">代理商</i-col>
      <i-col span="7">签约额(万元)</i-col>
      <i-col span="7">金额涨幅</i-col>
    </Row>
    <Row v-for="(item,index) in listContractAmountByCompanyData" :key="'21_' + index" :gutter="8" class="p-t-10 text-right">
      <i-col span="10" class="text-left">
        <Tooltip v-if="item.name.length>8" placement="right-start" :content="item.name">
          {{item.name.substr(0,8)}}..
        </Tooltip>
        <span v-else>{{item.name}}</span>
      </i-col>
      <i-col span="7">{{formatMoneyByThousand(item.value)}}</i-col>
      <i-col span="7">
        <span :class="getChainRateColor(item.chainRate)">{{item.chainRate}}%</span>
      </i-col>
    </Row>
    <p v-if="listContractAmountByCompanyData.length===0" class="remark p-t-20 text-center">暂无数据</p>

    <p class="situation-kpi-title m-t-20 m-b-5">订单转化率</p>
    <Row  class="table-title text-right" :gutter="8">
      <i-col span="8" class="text-left">渠道</i-col>
      <i-col span="3" class="text-center">订单</i-col>
      <i-col span="3" class="text-center">合同</i-col>
      <i-col span="4">转化率</i-col>
      <i-col span="6">转化率涨幅</i-col>
    </Row>
    <Row v-for="(item,index) in listContractOrderCvrData" :key="'3_' + index" :gutter="8" class="p-t-10 text-right">
      <i-col span="8" class="text-left">
        <Tooltip v-if="item.name.length>8" placement="right-start" :content="item.name">
          {{item.name.substr(0,8)}}..
        </Tooltip>
        <span v-else>{{item.name}}</span>
      </i-col>
      <i-col span="3" class="text-center">{{item.totalNumber}}</i-col>
      <i-col span="3" class="text-center">{{item.actualNumber}}</i-col>
      <i-col span="4">{{item.rate}}%</i-col>
      <i-col span="6">
        <span :class="getChainRateColor(item.chainRate)">{{item.chainRate}}%</span>
      </i-col>
    </Row>
    <p v-if="listContractOrderCvrData.length===0" class="remark p-t-20 text-center">暂无数据</p>

    <p class="situation-kpi-title m-t-20 m-b-5">合同类型</p>
    <Row  class="table-title text-right" :gutter="8">
      <i-col span="6" class="text-left">类型</i-col>
      <i-col span="6" class="text-center">数量</i-col>
      <i-col span="6">签约额(万元)</i-col>
      <i-col span="6">占比</i-col>
    </Row>
    <Row v-for="(item,index) in countContractAmountGbData" :key="'4_' + index" :gutter="8" class="p-t-10 text-right">
      <i-col span="6" class="text-left">{{item.name}}</i-col>
      <i-col span="6" class="text-center">{{item.number}}</i-col>
      <i-col span="6">{{formatMoneyByThousand(item.value)}}</i-col>
      <i-col span="6">{{item.rate}}%</i-col>
    </Row>
    <p v-if="countContractAmountGbData.length===0" class="remark p-t-20 text-center">暂无数据</p>

    <p class="situation-kpi-title m-t-20 m-b-5">合同变更</p>
    <Row  class="table-title text-right" :gutter="8">
      <i-col span="4" class="text-left">类型</i-col>
      <i-col span="4" class="text-center">数量</i-col>
      <i-col span="6">签约额(万元)</i-col>
      <i-col span="4">占比</i-col>
      <i-col span="6">金额涨幅</i-col>
    </Row>
    <Row v-for="(item,index) in listContractAlterationData" :key="'5_' + index" :gutter="8" class="p-t-10 text-right">
      <i-col span="4" class="text-left">{{item.name}}</i-col>
      <i-col span="4" class="text-center">{{item.number}}</i-col>
      <i-col span="6">{{formatMoneyByThousand(item.value)}}</i-col>
      <i-col span="4">{{item.rate}}%</i-col>
      <i-col span="6">
        <span :class="getChainRateColor(item.chainRate)">{{item.chainRate}}%</span>
      </i-col>
    </Row>
    <p v-if="listContractAlterationData.length===0" class="remark p-t-20 text-center">暂无数据</p>

    <!-- <h2 class="text-center m-t-10">今日在刊统计</h2>
    <Row class="p-t-5 text-center">
      <i-col span="24">
        <RadioGroup v-model="chooseGbType" size="small" type="button" button-style="solid">
            <Radio label="1" style="width:162px">行业</Radio>
            <Radio label="2" style="width:162px">品牌</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row class="table-title" :gutter="8">
      <i-col span="12">类目</i-col>
      <i-col span="6" class="text-center">投放数量</i-col>
      <i-col span="6" class="text-right">占比</i-col>
    </Row>
    <Row v-for="(item,index) in listCountIssueData" :key="'6_' + index" :gutter="8" class="p-t-5">
      <i-col span="12">
        <Tooltip v-if="item.name.length>12" placement="right-start" :content="item.name">
          {{item.name.substr(0,12)}}..
        </Tooltip>
        <span v-else>{{item.name}}</span>
      </i-col>
      <i-col span="6" class="text-center">{{item.number}}</i-col>
      <i-col span="6" class="text-right">{{item.rate}} %</i-col>
    </Row>
    <div v-show="showArray.length>currentPageSize" class="paging_style">
      <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
    </div> -->

  </div>
</template>

<script>
import {
  listContractRevenueByType, listContractRevenueByClient, listContractOrderCvr,
  listContractAlteration, listCountIssue, listContractAmountByCompany
} from '@/api/dw/manage'
import { countContractAmountGb } from '@/api/dw/datav'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,

      listContractRevenueByTypeData: [],
      listContractRevenueByClientData: [],
      listContractOrderCvrData: [],
      listContractAlterationData: [],
      listContractAmountByCompanyData: [],

      countContractAmountGbData: [],
      chooseGbType: '1',
      listCountIssueData: [],
      showArray: [],
      currentPageSize: 5,
      currentPageNumber: 1
    }
  },
  mounted () {
    this.initPageData()
    this.loadContractInfo()
    // this.loadIssue()
  },
  methods: {
    initPageData () {
      const queryModel = {
        compareEndDate: this.compareSchedule.endDate,
        compareStartDate: this.compareSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate
      }

      listContractRevenueByType(queryModel).then(res => {
        this.listContractRevenueByTypeData = res
      })
      listContractRevenueByClient(queryModel).then(res => {
        this.listContractRevenueByClientData = res
      })
      listContractOrderCvr(queryModel).then(res => {
        this.listContractOrderCvrData = res
      })
      listContractAlteration(queryModel).then(res => {
        this.listContractAlterationData = res
      })
      listContractAmountByCompany(queryModel).then(res => {
        this.listContractAmountByCompanyData = res
      })
    },
    loadContractInfo () {
      const postData = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        gbType: 'type'
      }
      countContractAmountGb(postData).then(res => {
        this.countContractAmountGbData = res
      })
    },
    loadIssue () {
      const queryModel = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        key: this.chooseGbType
      }
      listCountIssue(queryModel).then(res => {
        this.showArray = res
        this.handlePageChange(1)
      })
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.listCountIssueData = newArray
    },
    formatMoneyByThousand (number) {
      return (number * 0.0001).toFixed(2)
    },
    getChainRateColor (number) {
      let color = 'text-red'
      if (number < 0) {
        color = 'text-green'
      } else if (number === 0) {
        color = 'text-yellow'
      }
      return color
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    compareSchedule () {
      return this.$store.state.situationWeb.compareSchedule
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.initPageData()
        this.loadContractInfo()
        // this.loadIssue()
      }
    },
    compareSchedule: {
      deep: true,
      handler (newValue) {
        this.initPageData()
      }
    },
    chooseGbType (val) {
      this.loadIssue()
    }
  }
}
</script>

<style>

</style>
